import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/desktop/_templates/chapter_3/sub_3/slide1';


const Slide = () => {
  const query = useStaticQuery(graphql`
    query {
      before: mdx(frontmatter: {language: {eq: "UA"}, title: {eq: "slide-3-3-1-before"}}) {
        body
      },
      after: mdx(frontmatter: {language: {eq: "UA"}, title: {eq: "slide-3-3-1-after"}}) {
          body
      },
      description: mdx(frontmatter: {language: {eq: "UA"}, title: {eq: "slide-3-3-1"}}) {
          body
      },
    }
  `);
  return (
    <Template query={query} />
  );
};


export default Slide;
